<template>
  <v-dialog v-model="active" width="600" persistent>
    <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
      <slot :name="slot" v-bind="scope" />
    </template>
    <v-card v-if="sending">
      <v-card-title>Sending Emails...</v-card-title>
      <v-card-text>
        <v-progress-linear :value="(numSent + numErr) / numToSend * 100"></v-progress-linear>
        <p>Sent {{ numSent + numErr }} out of {{ numToSend }} emails, with {{ numErr }} errors.</p>
      </v-card-text>
    </v-card>
    <v-card v-else-if="numSent > 0 || numErr > 0">
      <v-card-title>Emails Sent</v-card-title>
      <v-card-text>
        <p>The process has been completed. Sent {{ numSent }} out of {{ numToSend }} emails with {{ numErr }} errors.</p>
        <v-dialog v-model="sentDetailDialog" fullscreen>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on">View details</v-btn>
          </template>
          <v-card>
            <v-toolbar>
              <v-toolbar-title>Email Details</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn icon @click="sentDetailDialog = false">
                <v-icon>fal fa-times</v-icon>
              </v-btn>
            </v-toolbar>
            <v-data-table :items="sentData" :headers="headers"></v-data-table>
          </v-card>
        </v-dialog>
      </v-card-text>
      <v-card-actions>
        <v-btn text @click="active = false">Close</v-btn>
      </v-card-actions>
    </v-card>
    <v-card v-else>
      <v-card-title>Send Email</v-card-title>
      <v-card-text>
        <v-container grid-list-md>
          <v-row>
            <v-col :cols="12">
              <v-text-field v-model="subject" label="Subject" outlined hide-details></v-text-field>
            </v-col>
            <v-col :cols="12">
              <span class="label">Email Body</span>
              <editor v-model="body" :mergeFields="mergeFields" outlined></editor>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-btn text @click="close()" :disabled="sending">Cancel</v-btn>
        <v-btn text color="success" @click="send()" :disabled="!formValid">Send</v-btn>
        <v-spacer></v-spacer>
        <v-btn text color="error" @click="clearForm" :disabled="formEmpty">Clear Form</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { computed, ref, watch } from '@vue/composition-api'
import { sleep } from '../../helpers/functions'

export default {
  components: {
    Editor: () => import('../forms/HTMLEditor')
  },
  props: {
    from: {
      type: String,
      default: ''
    },
    emailField: {
      type: String,
      default: 'email'
    },
    data: {
      type: Array,
      required: true
    },
    mergeFields: {
      type: Array,
      default: () => { return [] }
    }
  },
  setup (props, { root }) {
    const user = computed(() => root.$store.state.user.spoof || root.$store.state.user)
    const active = ref(false)
    const subject = ref('')
    const body = ref('')

    const _from = ref(props.from)
    watch(() => props.from, () => { _from.value = props.from })
    const sentData = ref([])

    const formValid = computed(() => subject.value !== '' && body.value !== '')
    const formEmpty = computed(() => subject.value === '' && body.value === '')
    function clearForm () {
      subject.value = ''
      body.value = ''
    }
    function close () {
      clearForm()
      active.value = false
    }

    const sending = ref(false)
    const numToSend = ref(0)
    const numSent = ref(0)
    const numErr = ref(0)
    async function send () {
      sending.value = true
      if (_from.value === '') _from.value = user.value.email
      numToSend.value = props.data.length
      for (let i = 0; i < props.data.length; i++) {
        const row = props.data[i]
        const obj = await getEmailObj(row)
        console.log(obj, row)
        if (obj !== false) {
          try {
            await root.$feathers.service('system/email').create(obj)
            sentData.value.push({ ...row, status: 'Sent', obj })
            numSent.value++
            await sleep(200)
          } catch (error) {
            sentData.value.push({ ...row, status: 'Error', error, obj })
            console.error(error)
            numErr.value++
          }
        } else {
          sentData.value.push({ ...row, status: 'Error', error: 'Either to, from, subject, or body were empty after parsing', obj: { to: '', from: '', subject: '', html: '' } })
          console.error('getEmailObj function returned false')
          numErr.value++
        }
      }
      sending.value = false
    }
    const mergeHash = computed(() => {
      const obj = {}
      for (const { label, value } of props.mergeFields) {
        obj[label] = value
      }
      return obj
    })
    async function getEmailObj (row) {
      if (!(props.emailField in row)) {
        console.error('The email field (' + props.emailField + ') was not found in the row: ', row)
        return false
      }
      const obj = {
        from: _from.value,
        to: row[props.emailField],
        subject: subject.value,
        html: body.value
      }
      const subjRegex = new RegExp(/{{[\s\w\d()]*}}/ig)
      const subjMatches = obj.subject.match(subjRegex) || []
      for (let i = 0; i < subjMatches.length; i++) {
        const label = subjMatches[i].substring(2, subjMatches[i].length - 2)
        const field = mergeHash.value[label]
        const value = field in row ? row[field] : ''
        obj.subject = obj.subject.replaceAll(subjMatches[i], value)
      }
      const codeRegex = new RegExp(/<code>[\s\w\d()]*<\/code>/ig)
      const bodyMatches = obj.html.match(codeRegex) || []
      for (let i = 0; i < bodyMatches.length; i++) {
        const label = bodyMatches[i].substr(6, bodyMatches[i].length - 13)
        const field = mergeHash.value[label]
        const value = field in row ? row[field] : ''
        obj.html = obj.html.replaceAll(bodyMatches[i], value)
      }
      // obj.to = 'jon.moon@covenant.edu'
      return obj.to !== '' && obj.from !== '' && obj.subject !== '' && obj.html !== '' ? obj : false
    }

    const sentDetailDialog = ref(false)
    const headers = computed(() => {
      const arr = []
      arr.push({ text: 'Status', value: 'status' })
      arr.push({ text: 'From', value: 'obj.from' })
      arr.push({ text: 'To', value: 'obj.to' })
      arr.push({ text: 'Subject', value: 'obj.subject' })
      arr.push({ text: 'Email Body', value: 'obj.html', width: 150 })
      arr.push({ text: 'Error', value: 'error' })
      for (const { label: text, value } of props.mergeFields) {
        arr.push({ text, value })
      }
      return arr
    })

    return {
      active,
      subject,
      body,
      sentData,
      formValid,
      formEmpty,
      clearForm,
      close,
      sending,
      numToSend,
      numSent,
      numErr,
      send,
      mergeHash,
      sentDetailDialog,
      headers
    }
  }
}
</script>
